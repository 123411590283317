import PropTypes from 'prop-types';
import React, {useState} from 'react';
import styled from 'styled-components';

import {useLocation} from '@reach/router';
import {usePageContent} from 'src/functionality/content-provider';

import Page from 'src/components/functional/page';
import {Container} from 'react-bootstrap';
import {theme} from 'styles/theme';
import PlainHeroSection from 'src/components/functional/hero-section-plain';
import placeholderImage from 'images/placeholder.jpg';
import {H3, H4} from 'src/components/functional/headings';
import ContentBlock from 'src/components/functional/content-block';
import MoreAboutUs from 'src/components/functional/more-about-us-section';

import {getResource} from 'src/functionality/resources-provider';

import Tile from 'src/components/functional/tile';

const CenteredDocument = styled.div`
  display: flex;
  justify-content: center;
`;

const Wrapper = styled(Container)`
  display: flex;
  margin-top: 1em;
  flex-direction: column;
`;

const TrainingPage = ({pageContext}) => {
  const location = useLocation();
  const content = usePageContent(location.pathname, pageContext.content);
  const [viewTrainingModules, setViewTrainingModules] = useState(false);
  return (
    <Page white="true">
      <PlainHeroSection
        backgroundColor={theme.mediumGray}
        breadcrumbs="true"
        content={content}
        tag="T01"
        bgImage={placeholderImage}
      />
      <Wrapper>
        <ContentBlock
          header={(props) => <H3 {...props}/>}
          content={content}
          tag='T02'
        />
        <section>
          <hr />
          <ContentBlock
            header={(props) => <H3 {...props}/>}
            content={content}
            tag='T03'
          />
          <button
            className='plain-button'
            onClick={() => setViewTrainingModules(!viewTrainingModules)}
          >
            <h4>
            View Training Modules &nbsp;
              {viewTrainingModules ?
              <i className="fa fa-angle-up"/> :
              <i className="fa fa-angle-down"/>
              }
            </h4>
          </button>
          {viewTrainingModules &&
          <>
            <CenteredDocument>
              <Tile
                isRounded
                type='document'
                onClick={() =>
                // eslint-disable-next-line max-len
                  getResource('resources/Brochures/ABI-Training-Modules.pdf')
                      .then((url) => window.open(url))}
                title='Training Modules Brochure'
              />
            </CenteredDocument>
            {['T04-1', 'T04-2', 'T04-3', 'T04-4'].map((tag, key) => {
              return (
                <ContentBlock
                  key={key}
                  header={(props) =>
                    <H4
                      display={6}
                      className="text-primary"
                      {...props}
                    />}
                  content={content}
                  tag={tag}
                />
              );
            })}
          </>
          }
          <hr />
        </section>
        <ContentBlock
          header={(props) => <H3 {...props}/>}
          content={content}
          tag='T06'
        />

        <ContentBlock
          header={(props) => <H3 {...props}/>}
          content={content}
          tag='T05'
        />
      </Wrapper>
      <MoreAboutUs />
    </Page>
  );
};


TrainingPage.propTypes = {
  pageContext: PropTypes.shape({
    content: PropTypes.object,
  }),
};

export default TrainingPage;
